.technology-section {
  /* background: linear-gradient(0deg, rgba(141, 166, 219, 1) 0%, rgba(255, 255, 255, 1) 100%); */
  /* padding-top: 10rem;
    padding-bottom: 10rem; */
  /* height: 100vh; */
  padding-top: 1.8rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}
.dselected-technology {
  text-align: center;
  font-size: 20px;
  color: #666666;
  font-weight: 700;
  position: relative;
  font-family: "Poppins", sans-serif;
  padding-bottom: 10px;
}

.selected-technology {
  /* text-decoration: underline; */
  border-bottom: 3px solid #555;
  text-align: center;
  font-size: 20px;
  color: #555;
  cursor: default;
  /* background-color: #fff; */
  font-weight: 700;
  position: relative;
  font-family: "Poppins", sans-serif;
  padding-bottom: 10px;
}
.tech-title {
  font-size: 32px;
  color: black;
  font-family: Montserrat;
  z-index: 2;
  position: relative;
  font-weight: 500;
}

.technologypart {
  margin: 0 auto;
  margin-top: 50px;
  width: 100%;
}
.technology {
  display: flex;
  justify-content: center;
}
.tech {
  margin: 0 8px;
  text-align: center;
}
.img-resize {
  width: 100px;
  height: 100px;
  background: #f5f5f5;
  padding: 25px;
  object-fit: contain;
}

.technologyTab {
  display: flex;
  justify-content: center;
  gap: 50px;
  max-width: 100vw;
  overflow: auto;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 688px) {
  .img-resize {
    width: 80px;
    height: 80px;
    background: #f5f5f5;
    padding: 18px;
    object-fit: contain;
  }
}
