.mobile-app {
    padding-top: 50px;
    padding-bottom: 70px;
    background: #ffffff;
}
.mobile-app .box {
    margin-top: 40px;
    margin: 12px;
}
.mobile-app ul li {
    width: 49%;
    display: inline-block;
    text-align: center;
    padding-top: 20px;   
    background: #eef3ff;
    margin: 0.5rem 0;   
}

.mobile-app ul li a {
    display: block;
    text-decoration: none; 
}
a {
    color: rgb(32, 75, 145);
}
.full-width-image-softwate-consulting {
    object-fit: contain;
    transition: all .5s ease-in-out;
    position: relative;
  
}
.full-width-image-softwate-consulting a  p{
    font-size: .9rem !important;
    color: black !important;
    line-height: 1.5 !important    
}

@media (max-width: 776px) {
    .mobile-app ul li {
        width: 100%; 
        margin: 0.5rem 0;   
    }
}

.advantages {
    padding-top: 50px;
    padding-bottom: 70px;
    background: #fff;;
}
.advantages .box {
    margin-top: 40px;
    margin: 12px;
}
.advantages ul li {
    width: 32%;
    display: inline-block;
    text-align: center;
    padding-top: 20px;   
    background: #fff;
    margin: 0.5rem 0;   
}

.advantages ul li a {
    display: block;
    text-decoration: none; /* Remove the underline */
}
a {
    color: rgb(32, 75, 145);
}
.full-width-image-softwate-consulting {
    object-fit: contain;
    transition: all .5s ease-in-out;
    position: relative;
  
}
.full-width-image-softwate-consulting a  p{
    font-size: .9rem !important;
    color: black !important;
    line-height: 1.5 !important
    
}

@media (max-width: 776px) {
    .advantages ul li {
        width: 47%; 
        margin: 0.5rem 0;   
    }
}
@media (max-width: 576px) {
    .advantages ul li {
        width: 100%; 
    }
}
