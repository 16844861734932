.serviceName {
    color: #0b4040;
    text-decoration: none;
    transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
}

.serviceName:hover {
    color: #0b4040;
    text-decoration: underline;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');


.background-white-color {
    background-color: white;
}

.cardText {
    /* font-family: Ghawar-Light, Helvetica, Arial, sans-serif; */
    /* font-size: .875rem;
    line-height: 1.375rem;
    letter-spacing: .0075rem;
    padding-bottom: 0.625rem;
    color: #676a6e;
    margin: 0;
    font-family: 'Poppins', sans-serif; */

    font-size: 12px;
    color: #7f7f7f;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 10px;
    /* text-transform: lowercase; */
}

.testCss h1 {
    color: yellow !important;
}

.btn-see-more {
    border: 2px solid #0b4140;
    padding: 10px;
    width: 20%;
    color: #0d6f6e;
    font-weight: bold;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}

.btn-see-more:hover {
    border: 2px solid #0b4140;
    color: white;
    background-color: #0b4140;
    padding: 10px;
    width: 20%;

}

.card-title {
    /* font-family: ManifaPro2-Light, Frutiger, Muna, Helvetica, Arial, sans-serif;
    font-size: 1.5rem;
    line-height: 2.125rem;
    letter-spacing: .014375rem;
    padding-bottom: 0.625rem;
    margin: 0; */
    font-size: 16px;
    color: #000;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    /* text-transform: capitalize; */
}





.serviceContainer{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 20px;

}

/* .serviceColItem:nth-child(5){
    grid-column: 2 / 3;
}
.serviceColItem:nth-child(6){
    grid-column: 3 / 4;
} */



@media (min-width:1024px) {
    .serviceContainer{
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 20px;
    }
}