.hireCard {
}

.singleHireCart,
.singleHireCart_top {
  background-color: #1b71de;
  color: white;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 20px;
  position: relative;
}
.singleHireCart h1 {
  position: absolute;
  bottom: -38px;
  left: -154%;
  color: black;
  font-size: 20px;
  width: max-content;
}
.singleHireCart_top h1 {
  position: absolute;
  top: -30px;
  left: -154%;
  color: black;
  font-size: 20px;
  width: max-content;
}
.singleHireCart:last-child h1 {
  left: -200%;
}
.singleHireCart_top:first-child h1 {
  left: 0%;
}

.middleBorder {
  border-bottom: 2px solid #3ccef6;
  width: 16%;
}

.stepsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
}

@media (max-width: 1024px) {
  .stepsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
  }
  .stepsParentContainer {
    box-sizing: border-box;
    padding-right: 147px;
  }

  .middleBorder {
    border: none;
    width: 0;
    border-left: 2px solid #3ccef6;
    height: 100px;
  }

  .singleHireCart h1,
  .singleHireCart_top h1 {
    position: absolute;
    top: 27%;
    left: 58px;
    color: black;
    font-size: 20px;
    width: max-content;
  }
  .singleHireCart_top h1 {
    position: absolute;
    top: 27%;
    left: -400%;
    color: black;
    font-size: 20px;
    width: max-content;
  }

  .singleHireCart_top:first-child h1 {
    left: -356%;
  }
  .singleHireCart:last-child h1 {
    left: 113%;
  }
}
@media (max-width: 778px) {
  

  .singleHireCart h1,
  .singleHireCart_top h1 {
    position: absolute;
    top: 27%;
    left: 58px;
    color: black;
    font-size: 20px;
    width: max-content;
  }
  .singleHireCart_top h1 {
    position: absolute;
    top: 27%;
    left: 58px;
    color: black;
    font-size: 20px;
    width: max-content;
  }

  .singleHireCart_top:first-child h1 {
    left: 58px;
  }
  .singleHireCart:last-child h1 {
    left: 58px;
  }
}


