.softwate-consulting {
    padding-top: 50px;
    padding-bottom: 70px;
    background: #ffffff;
    /* border-bottom: 5px solid #fff; */
}
.softwate-consulting .box {   
    margin: 12px;
    margin-top: 40px;
}
.softwate-consulting ul li {
    width: 32%;
    display: inline-block;
    text-align: center;
    padding-top: 20px;
    transition: 0.5s all;
   
    background: #eef3ff;
    margin: 0.5rem 0;   
}

.softwate-consulting ul li a {
    display: block;
    text-decoration: none; /* Remove the underline */
}
a {
    color: rgb(32, 75, 145);
}
.full-width-image-softwate-consulting {
    object-fit: contain;
    transition: all .5s ease-in-out;
    position: relative;
  
}
.full-width-image-softwate-consulting a  p{
    font-size: .9rem !important;
    color: black !important;
    line-height: 1.5 !important
    
}


/* .full-width-image-softwate-consulting:hover a {
 
    color: #007bff;
} */

.loading-line {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px; 
    width: 0;
    background-color: #007bff;
    transition: width 0.4s ease-in-out;
}

.full-width-image-softwate-consulting:hover .loading-line {
    width: 100%;
}


/* .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
} */

@media (max-width: 776px) {
    .softwate-consulting ul li {
        width: 47%; 
        margin: 0.1rem;  
    }
}
@media (max-width: 576px) {
    .softwate-consulting ul li {
        width: 100%; 
    }
}
