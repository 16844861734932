.custom-soft {
    padding-bottom: 70px;
    border-bottom: 5px solid #fff;
    margin: 7px;
}

.box {
    margin-top: 40px;
} 

.custom-soft ul li {
    width: 30%;
    display: inline-block;
    text-align: center;
    padding: 40px 0 20px;
    transition: 0.5s all;
    background: #eef3ff;
    margin: .5%;
    /* margin: 0.5rem 2rem; */
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

.border-right {
    border-right: 1px solid #e5e5e5;
}

.custom-soft ul li a {
    display: block;
    text-decoration: none;
}

a {
    color: rgb(32, 75, 145);
}

.full-width-image-custom-soft {
    object-fit: contain;
    position: relative;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    overflow: hidden;

}


.full-width-image-custom-soft:hover a {

    color: #007bff;
}

.loading-line {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 0;
    background-color: #007bff;
    transition: width 0.4s ease-in-out;
}

.full-width-image-custom-soft:hover .loading-line {
    width: 100%;
}

.full-width-image-custom-soft img {
    height: auto;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

}

.full-width-image-custom-soft .text-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    transform: translateY(100%);
    text-align: left;
    color: black;

}

.full-width-image-custom-soft:hover img {
    transform: translateY(100%);
    opacity: 0;
}

.full-width-image-custom-soft:hover .text-container {
    opacity: 1;
    transform: translateY(0);
    text-align: left;
}

/* -------------- Services ---------------------- */
.service {
    padding-top: 50px;
    padding-bottom: 70px;
    background: #eef3ff;
}

.service ul li {
    width: 30%;
    display: inline-block;
    text-align: center;
    padding-top: 20px;
    transition: 0.5s all;
    background: #ffffff;
    margin: 0.5rem;
}

.service ul li a {
    display: block;
    text-decoration: none;
}

a {
    color: rgb(32, 75, 145);
}

.full-width-image-service {
    object-fit: contain;
    transition: all .5s ease-in-out;
    position: relative;
}

.full-width-image-service a p {
    font-size: 1rem !important;
    color: black !important;
    line-height: 1.5 !important;
}

.full-width-image-service:hover a {

    color: #007bff;
}

.loading-line {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 0;
    background-color: #007bff;
    transition: width 0.4s ease-in-out;
}

.full-width-image-service:hover .loading-line {
    width: 100%;
}



@media (max-width: 776px) {
    .service ul li {
        width: 45%;
    }
}

@media (max-width: 576px) {
    .service ul li {
        width: 100%;
    }
}


/* ------------------- SDLC & manage ------------------ */

.sdlc {
    border-bottom: 5px solid #fff;
}
.sdlc ul li {
    width: 48%;
    display: inline-block;
    text-align: center;
}
.sdlc ul li.first-li {
    width: 30%;
}
.sdlc ul li.second-li {
    width: 70%;
}


.manage {
    padding-bottom: 70px;
    border-bottom: 5px solid #fff;
    margin: 7px;
}

.manage ul li {
    width: 32%;
    display: inline-block;
    text-align: center;
    padding: 20px 0 10px;
    transition: 0.5s all;
    background: #eef3ff;
    margin: .7% .5%;
}

.manage ul li a {
    display: block;
    text-decoration: none;

}





.sdlc-container {
    /* font-family: "Arial", sans-serif; */
    /* background-color: #f5f5f5; */
    margin: 0;
    padding: 20px;
  }

  .timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
  }

  .timeline-step {
    display: flex;
    margin: 20px 0;
    position: relative;
    padding-left: 40px;
  }

  .timeline-step::before {
    content: "";
    position: absolute;
    top: 0;
    left: 20px;
    width: 2px;
    height: 100%;
    background-color: #4caf50;
  }

  .timeline-icon {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    border: 3px solid #3d58f1;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4339f8;
    font-size: 20px;
  }

  .timeline-icon img {
    height: 60%;
    border-radius: 50%;
  }

  .timeline-content {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .title {
    margin: 0;
    color: #333;
    font-size: 1.3rem;
  }







@media (max-width: 1000px) {
    .serve-img {
        height: 12rem !important;
    }

    .sdlc ul li.first-li {
        width: 50% !important;
    }

    .sdlc ul li.second-li {
        width: 50% !important;
    }   

    .full-width-image-custom-soft .text-container{
        font-size: 1rem;
        line-height: 1.3;
    }
  
}

@media (max-width: 576px) {
    .pricing ul li {
        width: 100%;
    }

    .manage ul li {
        width: 100%;
    }

    .sdlc ul li.first-li {
        width: 100% !important;
    }

    .sdlc ul li.second-li {
        width: 100% !important;
    }
    .manage ul li {
        width: 100% !important;
    }
}

@media (max-width: 776px) {
    .custom-soft ul li {
        width: 100%;
    }

    .manage ul li {
        width: 49%;
    }
    .sdlc-img{
        height: 20rem !important;
    }
    .sdlc ul li.first-li {
        width: 100% !important;
    }

    .sdlc ul li.second-li {
        width: 100% !important;
    }  
}
