.condeContainer {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 20px;
    margin-top: 76px;
}
@media (max-width: 1024px) {
    
  .condeContainer {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 40px;
    margin-top: 76px;
    padding: 0 20px;
}
}

.codeSingleCard li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px;
  width: 10px;
  height: 10px;
  background-color: #005eb8;
}

.codeSingleCard li {
  font-size: 20px;
  position: relative;
  padding-left: 20px;
  font-weight: 200;
  margin-top: 20px;
}
.codeSingleCard ul {
  list-style: none;
}
.codeSingleCard h1 {
  font-size: 30px;
  text-align: center;
 
  margin-bottom: 30px;
}

.codeSingleCard:hover {
  background-color: #fffdf5;
  border-radius: 60px 0px;
  scale: 0.9;
}

.codeSingleCard {
  background-color: white;
  border: 1px solid rgba(128, 128, 128, 0.295);
  padding: 15px;
  transition-duration: 500ms;
}
