.centerContainer{
    width: 1700px;
    margin: 0 auto;

}
.AnalyticsGrid{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 30px;
    padding: 0,10px;
}

.analyticsCart {
   border: 1px solid black;
  
   padding: 15px;
   transition-duration: 500ms;
   
}

.analyticsCart:hover {
   background-color: #fffdf5;
   border-radius: 60px 0px;
   scale: 0.9;
   
}

.analyticsCart h1{
    font-size: 30px;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 30px;
}
.analyticsCart li{
font-size: 20px;
position: relative;
padding-left: 20px;
font-weight: 400;

}
.analyticsCart li::before{
content: "";
position: absolute;
left: 0;
top: 5px;
width: 10px;
height: 10px;
background-color: #005eb8;

}
.analyticsCart ul{
list-style: none;


}

.expand{
height: auto;
transition: height 0.5s ease;
}

.collaps{
height: 120px;
overflow: hidden;
transition: height 0.5s ease;
}

.buttonDiv{
    text-align: right;
}
.buttonDiv button{
    border: none;
    font-size: 50px;
    border-radius: 100%;
    background-color: transparent;
}

/* .analycisTittle{
   
} */

@media (max-width: 1700px) {
    
    .centerContainer{
        width: 100%;
        margin: 0 auto;
    }
}

.Sub-tittle{
    font-size: 30px;
    text-align: center;
    position: relative;
    display: inline-block;
    color: #2980b9;
    margin-top: 70px;
}
.Sub-tittle::after{
   content: "";
   position: absolute;
   bottom:0;
   left: 0;
   width: 100%;
   background-color: #005eb8;
   height: 2px;

}

@media (min-width: 1024px) {
    
 
    .AnalyticsGrid{
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
       padding: 0,10px;
    }
}
