.corporationModelcontainer {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
}



.corporationCart {
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.301);
  padding: 20px 10px;
  transition-duration: 500ms;
}
.corporationCart:hover {
  background-color: #fffdf5;
  border-radius: 60px 0px;
  scale: 0.9;
}

.corporationCart img {
  width: 100px;
  height: 100px;
}
.corporationCart h1 {
  font-size: 30px;
}
.corporationCart p {
  font-size: 16px;
  margin: 25px 0;
}
.corporationCart button {
  border: 1px solid rgba(19, 0, 190, 0.589);
  color: #0c66bb;
  font-weight: 200;
  font-size: 20px;
  background-color: transparent;

  display: inline-flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.corporationCart button span {
  font-size: 27px;
}


@media (min-width: 1024px) {
    .corporationModelcontainer {
      display: flex;
      padding: 0;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
      text-align: center;
    }
    .corporationCart {
      width: 100%;
      border: 1px solid rgba(128, 128, 128, 0.301);
      padding: 20px 10px;
      transition-duration: 500ms;
    }
  }