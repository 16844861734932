.centerContainer {
  width: 1300px;
  margin: 0 auto;
}

.tittleText {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 30px;
  color: "black";
  font-family: "Montserrat";
  font-size: "40px";
  font-weight: 500;
}

.developer_container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
}

.developers_cart {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
  border: none;
  background-color: rgba(240, 240, 240, 0.76);
  transition-duration: 0.3s;
  position: relative;
}
.developers_cart::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 5px;
  background-color: #fec007;
  transition-duration: 0.3s;
}
.developers_cart:hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #fec007;
}

.developers_cart:hover {
  transform: scale(0.9);
  background-color: rgba(179, 177, 177, 0.76);
}

.developers_cart img {
  width: 60px;
}
.developers_cart h1 {
  font-size: 20px;
}

@media (max-width: 1300px) {
  .centerContainer {
    width: 100%;
    margin: 0 auto;
  }
}
@media (max-width: 1024px) {
  .developer_container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 20px;
  }
}
