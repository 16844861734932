/* .LanguagecenterContainer{
    width: ;
} */

.languageContainer {
  height: auto;
  background-color: #d6efff;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 20px;
  padding: 5px;
}


.imagesContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.imagesContainer img {
  width: 40px;
  height: 25px;
  object-fit: contain;
  background-color: white;
  padding: 2px;
  border-radius: 5px;
}

.tittleText{
  text-align: center;
  margin-top: 100px;
  margin-bottom: 30px;
  color: "black";
  font-family: "Montserrat";
  font-size: "40px" !important;
  font-weight: 500;
}

@media (min-width: 1024px) {
  .languageContainer {
    height: auto;
    background-color: #d6efff;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    padding: 50px;
  }
  .imagesContainer img {
    width: 60px;
    height: 45px;
    object-fit: contain;
    background-color: white;
    padding: 2px;                                                                                                                                                                                                                                               
    border-radius: 5px;
  }

  
}
