.offered-fluid .row {
    position: relative;
}
.offered-fluid h2 {
    color: black;
    font-weight: 500;
    font-family: Montserrat;
    font-size: 32px;
}
.offered-fluid p {
    text-transform: uppercase;
    color: black;
    font-weight: 300;
    font-family: 'Poppins';
    letter-spacing: 1px;
    margin-top: 20px;
    position: relative;
    display: inline-block;
}

.offered_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.offered {
    padding-top: 50px;
    background: white;
    border-bottom: 5px solid #fff;
}
.offered .box {
    margin-top: 10px;
}
.offered ul li {
    width: 25%;
    display: inline-block;
    text-align: center;
    transition: 0.5s all;
}

.offered ul li a {
    display: block;
    text-decoration: none; 
}



.offered-col{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    background:  #002448;
    padding: 1rem;
    width: 23%;
    margin: 1%; 
    border-radius: 5px;
        
}

.offered-col a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  
}

.offered-col:hover {
    background: #004080; 
    transform: scale(1.05); 
}

#offeredSolutionParagraph{
   
    font-weight: 400;
    font-size: 1rem;
}


@media only screen and (max-width: 768px){
    
    .offered-col{
       
        width: 48%;
        margin: 1%; 
            
    }
}