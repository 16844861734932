.boxcontainer {
  height: auto;
  width: 70%;
  background-color: black;
  color: aliceblue;
  border-radius: 10px;
}

.sectionContainer {
  height: auto;
  width: 90%;
  color: rgb(7, 7, 7);
}

.sectionMergin {
  margin-bottom: 32px;
}

.themeColour {
  background-color: rgb(202, 250, 250);
}

.technologyBox {
  height: 100px;
  width: 90%;
  border-radius: 15px;
  /* margin-bottom: 20px; */
}

.technologyImageBox {
  height: 75%;
  width: 60%;
  overflow: hidden;
  background-color: rgb(158, 158, 199);
}

.technologyImage {
  max-width: 100%;
  max-height: 100%;
}

.benefitBox {
  height: auto;
  width: 80%;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 20px;
  background-color: rgb(142 223 223);
}

.benefitImageBox {
  height: 30%;
  width: 30%;
}

.benefitImageIcon {
  max-width: 100%;
  max-height: 100%;
}

/* .button {
  background-color: #000000;
  width: 10rem;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 100px;
} */

.readMore {
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 30px;
}

.imageBox {
  height: 500px;
  width: 600px;
}

.serviceCard {
  border: none;
}

.projectCard {
  border: none;
}

.location {
  width: 650px;
  height: 400px;
}

.bannerTextAlignment {
  text-align: right;
}

.KeywordContainer {
  display: inline-block;
}

.processImageBox {
  height: 250px;
  width: 70%;
}

.processImage {
  max-height: 100%;
  max-width: 100%;
}

.socialIconBox {
  height: 35px;
  width: 35px;
  display: inline-block;
}

.socialIcon {
  max-width: 100%;
  max-height: 100%;
}

.employeeImageBox {
  height: 150px;
  width: 250px;
}

.employeeImage {
  max-width: 100%;
  max-height: 100%;
}

.img-article {
  width: 35rem;
}

.custom-ui {
  text-align: center;
  width: 35rem;
  padding: 40px;
  background: white;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: black;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.btn-common {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-weight: 500;
  font-size: 1.2rem;
}

.btn-yes {
  width: 160px;
  padding: 10px;
  border: 1px solid #2ecc71;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #2ecc71;
  transition: 0.3s all ease-in-out;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
}

.btn-yes:hover {
  border: 1px solid #fff;
  background: #2ecc71;
  color: white;
}

.button-62:not([disabled]):focus,
.button-62:not([disabled]):hover {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5),
              -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5),
              .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}






/* CSS */
.btn-new {
  background: #5E5DF0;
  border-radius: 999px;
  box-shadow: #5E5DF0 0 10px 20px -10px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;  
}
.btn-new:hover{
  color: white !important;
}

.btn-large {
  height: 60px;
  width: 190px;
  text-align: center; 
  display: flex; 
  justify-content: center; 
  align-items: center;
}

.button-submit {
  background: linear-gradient(to bottom right, #191c51, #5aa7ff);
  border: 0;
  border-radius: 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  width: 8rem;
  margin-bottom: 1rem;

}

.button-submit:not([disabled]):focus {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(116, 71, 239, 0.5), .125rem .125rem 1rem rgba(65, 41, 110, 0.5);
}

.button-submit:not([disabled]):hover {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(116, 71, 239, 0.5), .125rem .125rem 1rem rgba(65, 41, 110, 0.5);
}