.projectContainer {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 40px;
}



.projectCard {
  position: relative;
}

.flotContainer {
  position: absolute;
  top: 0;
  right: 0;
  min-height: 300px;
  width: 50%;
  padding: 15px;
}
.flotContainer h1 {
  font-size: 20px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
}
.flotContainer p {
  font-size: ;
}
.flotContainer button {
  border: 1px solid rgb(63, 63, 224);
  color: rgb(63, 63, 224);
  padding: 5px;
  background-color: transparent;
  font-size: 17px;
}
.projectCard img{
  width: 100%;
  height: 400px;
  object-fit:cover;
  object-position: top left;
}

@media (max-width:1024px) {
  .projectContainer {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 40px;
  }
  .flotContainer {
    position: relative;
    top: 0;
    right: 0;
    min-height: 300px;
    width: 100%;
    padding: 15px;
  }
}