/* .serviceDetailsPage {
    display: flex;
} */

.serviceDetailsPage .container h1,
.serviceDetailsPage .container h2,
.serviceDetailsPage .row h2 {
  text-align: center;
  color: #2980b9;
}

.serviceDetailsPage .row h3 {
  text-align: center;
  color: #2980b9;
}

.breadCrumbText {
  text-decoration: none;
  color: #12bebe;
}

.breadCrumbText:hover {
  text-decoration: underline;
  color: #12bebe;
}

.serviceDetailsPage .row .card {
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 10px;
}

.serviceDetailsPage .row .card .card-text {
  padding: 10px;
  font-size: 1rem;
}

.serviceDetailsPage .container h2 {
  margin-top: 5rem !important;
}

.serviceDetailsPage .container p {
  font-family: Ghawar-Light, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: 0.0075rem;
  margin-bottom: 1.25rem;
  color: #676a6e;
  /* text-align: center; */
}

.serviceDetailsPage .container ul li {
  font-family: Ghawar-Light, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  color: #676a6e;
}

@font-face {
  font-family: "myFirstFont";
  src: url("./../../../Assets/Fonts/Dubai_Medium.ttf");
}

.serviceDetailsPage {
  font-family: "myFirstFont" !important;
}

.row{
  --bs-gutter-x: 1.5rem;
}
.row_modified{
  --bs-gutter-x: .5rem;
}
