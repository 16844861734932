.successContainer {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 40px;
    margin-top: 76px;
}
@media (max-width: 1024px) {
    
  .successContainer {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 40px;
    margin-top: 76px;
    padding: 0 20px;
}
}



.successSingleCard h1 {
  font-size: 30px;
  text-align: center;
 
  margin-bottom: 30px;
}

.successSingleCard:hover {
  background-color: #fffdf5;
  border-radius: 60px 0px;
  scale: 0.9;
}

.successSingleCard {
  background-color: white;
  border: 1px solid rgba(128, 128, 128, 0.295);
  padding: 15px;
  transition-duration: 500ms;
  cursor: pointer;
}
.successSingleCard div {
  text-align: center;
  font-size: 40px;
  color: #009dff;
}
