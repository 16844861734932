.colaps{
height: 200px;
overflow: hidden;
}
.expand{
height: auto
}
.cartcolaps{
height: 370px;
overflow: hidden;
}
.cartexpand{
height: auto
}

.animationDuration h1{
height: 60px;
font-size: 20px;
margin-bottom: 0px;

}
.animationDuration ul{
    padding: 5px;
}


.analyticsCart{
    border-radius:10px;
}

.analyticsCart li{
    font-size:15px !important;
    margin-top: 5px;
}

.analyticsCart button{
    font-size: 40px;
}